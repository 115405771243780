import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CourseComponent from "../components/CourseComponent"
import Spinner from "../components/Reusable/Spinner"
import { navigate } from "gatsby"
import { getCourseById } from "../services/course"
import * as sessionSelectors from "../redux/slices/session/selectors"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const CoursePage = ({ user, pageContext }) => {
  const [course, setCourse] = useState(null)
  const [response, setResponse] = useState(false)
  const [error, setError] = useState(false)

  const updateData = () => {
    getCourseById(pageContext.id)
      .then(resp => {
        setCourse(resp.data)
        setResponse(true)
      })
      .catch(error => {
        setError(error.response.status)
      })
  }

  useEffect(() => {
    updateData()
  }, [user])

  if (response)
    return (
      <Layout>
        <SEO title={course?.title ? course.title : ""} />
        <CourseComponent course={course} />
      </Layout>
    )

  if (response === false) {
    if (error === 404) navigate("/404")
    if (error === 500) navigate("/500")
  }

  return (
    <div
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner />
    </div>
  )
}

export default withConnect(CoursePage)
